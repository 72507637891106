var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"publish-action-sidebar"},[_c('select-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.action.action,
        'key': 'targets',
        'prefix': 'publish_action_',
        'options': _vm.$store.getters.boardTargetOptions,
        'multiple': true,
        'validation': 'required'
      }
    }},on:{"input":_vm.debounceHandlePostTargetsInput}}),_c('message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.messageEditorToPlainSetter,
      'args': {
        'model': _vm.action.action,
        'key': 'message',
        'prefix': 'publish_action_',
        'hasMedia': true,
        'targetToUpload': {
          'target': 'target',
          'target_id': _vm.$store.state.boardsState.activeBoard.board
        },
        'base-api-url': 'api.presscode.info/cabinet',
        'availableButtonsTypes': _vm.getNewDefaultButtons(),
        'buttonsHelpView': _vm.EditorButtonsHelpView,
        'placeholders': _vm.$store.getters.flowPlaceholders,
        'validation': 'required',
        'customMediaTypes': _vm.customMediaTypes,
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'style',
        'prefix': 'delay_',
        'options': _vm.stylesOptions,
        'notFoundOptionText': _vm.$t('style_not_found'),
        'settingWrapper': {
          'customiseValue': _vm.stylesOptions[0],
          'value': { value: null },
          'hasFieldButton': true,
          'buttonTitle': _vm.$t('customise'),
        },

      },
    }}}),_c('pi-tabs',{staticClass:"justify-center md:justify-start mb-5",attrs:{"multiline":"","tabs":_vm.tabs,"current":_vm.currentTab},on:{"tab-click":_vm.onTabChange}}),(_vm.action.action.delay)?[_c('a-alert',{attrs:{"show-icon":"","message":_vm.$t('publish_action_delay_help_message')}}),_c('select-input',{attrs:{"setup":{
        'func': _vm.swipeDelayType,
        'args': {
          'model': _vm.action.action.delay,
          'key': 'type',
          'prefix': 'delay_',
          'options': _vm.delayTypeOptions,
          'clearable': false,
        }
      }}}),(_vm.action.action.delay instanceof _vm.ProxyDelayDateType)?_c('delay-date-type',{key:"delay-date-type",attrs:{"delay":_vm.action.action.delay}}):_vm._e(),(_vm.action.action.delay instanceof _vm.ProxyDelayIntervalType)?_c('delay-interval-type',{key:"delay-interval-type",attrs:{"delay":_vm.action.action.delay}}):_vm._e(),(_vm.action.action.delay instanceof _vm.ProxyDelayPropertyType)?_c('delay-property-type',{key:"delay-property-type",attrs:{"delay":_vm.action.action.delay}}):_vm._e()]:[_c('a-alert',{attrs:{"show-icon":"","message":_vm.$t('publish_action_slots_help_message')}}),(_vm.slots)?_c('a-spin',{staticClass:"text-center mt-2",attrs:{"spinning":_vm.slotsLoading}},[_c('a-row',{attrs:{"gutter":[8,8]}},_vm._l((_vm.slots.slice(0, 10)),function(slot,index){return _c('a-col',{key:index,attrs:{"lg":12,"xs":24}},[_c('slot-item',{staticClass:"w-full",attrs:{"slot-item":slot,"readonly":""}})],1)}),1),_c('show-more-slots',{attrs:{"slot-items":_vm.slots.slice(10),"readonly":""}})],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }